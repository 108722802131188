const appConfig = {
  implicitFilters: {
    // [SearchFilterFields.PRODUCT_STOCK_STATUS]: {
    //   operator: SearchOperator.IN,
    //   field: SearchFilterFields.PRODUCT_STOCK_STATUS,
    //   value: [
    //     ProductStockStatus.IN_STOCK,
    //     ProductStockStatus.PARTIALLY_OUT_OF_STOCK,
    //     ProductStockStatus.OUT_OF_STOCK,
    //   ],
    // },
  },
  seoTemplates: {
    pdp: {
      title: '{title} - Vaaree',
      description: '{description}',
      ogDescription: '{description}',
      defaultProductCollection: {
        name: 'All Products',
        item: 'https://vaaree.com/collections/explore-all-products',
      },
    },
    collection: {
      defaultDescription: `Buy Best Home Decor Items & Essentials Online At Best Prices – Vaaree`,
      title:
        '{title} - Buy Best Home Decor Items & Essentials Online At Best Prices – Vaaree',
      description: '{description}',
      ogDescription: '{description}',
    },
    article: {
      keywords: `{title}, Vaaree Journals, Vaaree, vaaree.com`,
      description: '{description}',
      ogDescription: '{description}',
    },
    blog: {
      keywords: `{title}, Vaaree Journals, Vaaree, vaaree.com`,
      description: '{description}',
      ogDescription: '{description}',
    },
  },
};

export { appConfig };
